<script>
	import * as float from '@floating-ui/dom';
	import { onDestroy } from 'svelte';

	/** @type {string} */
	let targetElementId;
	export { targetElementId as for };

	export let onshow = () => {};
	export let onhide = () => {};

	/** @type {string} */
	let className = '';
	export { className as class };

	/** @type {float.Boundary} */
	export let boundary = 'clippingAncestors';

	/** @type {float.OffsetOptions} */
	export let offset = 4;

	/** @type {float.Padding} */
	export let padding = 0;

	/** @type {float.Placement} */
	export let placement = 'bottom';

	export let visible = false;

	/** @type {HTMLElement | null} */
	let targetElement = null;

	/** @type {HTMLElement} */
	let popupElement;

	function updatePosition() {
		if (!targetElement) {
			return;
		}
		float
			.computePosition(targetElement, popupElement, {
				placement,
				middleware: [
					float.offset(offset),
					float.flip({
						boundary,
						fallbackStrategy: 'initialPlacement',
						padding,
					}),
					float.shift({
						boundary,
						padding,
						// Stop the tooltip
						limiter: float.limitShift({
							offset({ rects, placement }) {
								const horizontalPadding = typeof padding === 'number' ? padding : Math.max(padding.left ?? 0, padding.right ?? 0);
								if (placement.startsWith('top') || placement.startsWith('bottom')) {
									return Math.min(rects.floating.width, rects.reference.width) - horizontalPadding;
								}
								return Math.min(rects.floating.height, rects.reference.height) - horizontalPadding;
							},
						}),
					}),
					float.hide({
						boundary,
						padding,
					}),
				],
			})
			.then(({ x, y, middlewareData }) => {
				if (!targetElement) {
					return;
				}
				Object.assign(popupElement.style, {
					left: `${x}px`,
					top: `${y}px`,
				});
				if (middlewareData.hide) {
					Object.assign(popupElement.style, {
						visibility: middlewareData.hide.referenceHidden ? 'hidden' : 'visible',
					});
				}
			});
	}

	/** @type {(() => void) | null}*/
	let cleanup = null;

	// TODO: Maybe we can pass targetElement as an argument to show()?
	export function show() {
		if (visible) {
			return;
		}
		if (targetElementId) {
			targetElement = document.getElementById(targetElementId);
		}
		if (targetElement) {
			visible = true;
			document.addEventListener('keydown', handleKeyDown, { capture: true });
			document.addEventListener('pointerdown', handlePointerDown, { capture: true });
			cleanup = float.autoUpdate(targetElement, popupElement, updatePosition);
			try {
				onshow();
			} catch (_) {
				// TODO: error handling
			}
		} else {
			console.warn('[Popup] unable to locate target element with id #%s', targetElementId);
		}
	}

	export function hide() {
		if (!visible) {
			return;
		}
		if (cleanup !== null) {
			cleanup();
			cleanup = null;
		}
		if (targetElement !== null) {
			document.removeEventListener('keydown', handleKeyDown, { capture: true });
			document.removeEventListener('pointerdown', handlePointerDown, { capture: true });
			targetElement = null;
		}
		visible = false;
		try {
			onhide();
		} catch (_) {
			// TODO: error handling
		}
	}

	export function toggle() {
		if (visible) {
			hide();
		} else {
			show();
		}
	}

	/** @param {KeyboardEvent} ev */
	function handleKeyDown(ev) {
		if (ev.key !== 'Escape') {
			return;
		}
		// @ts-ignore
		if (!ev.target || (!targetElement?.contains(ev.target) && !popupElement.contains(ev.target))) {
			return;
		}
		ev.preventDefault();
		ev.stopPropagation();
		hide();
	}

	/** @param {PointerEvent} ev */
	function handlePointerDown(ev) {
		// @ts-ignore
		if (!ev.target || targetElement?.contains(ev.target) || popupElement.contains(ev.target)) {
			return;
		}
		hide();
	}

	function handleClick() {
		hide();
	}

	onDestroy(hide);
</script>

<div bind:this={popupElement} class="popup {className}" class:visually-hidden={!visible} role="none" {...$$restProps} on:click={handleClick}>
	<slot></slot>
</div>

<style>
	.popup {
		background: var(--n0);
		border: 1px solid var(--n5);
		border-radius: 4px;
		box-shadow: var(--shadow-3);
		display: flex;
		flex-direction: column;
		overflow: hidden;
		position: absolute;
		z-index: var(--popup-z-index);
	}
</style>
